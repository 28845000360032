<template>
  <v-card class="mx-auto" v-if="contentor" style="">
    <div style="height: 25vh; padding-top:15px">
      <v-row align="center" class="mx-0">
        <v-progress-linear
          :value="contentor.nivel"
          height="10"
          striped
          :color="corBarra(contentor.nivel)"
        ></v-progress-linear>
      </v-row>
      <l-map
        :zoom="zoom"
        :options="{ zoomControl: false }"
        :center="[contentor.lat, contentor.lng]"
        v-if="showMap && gps"
        style="height: 100%; z-index:200;"
        ref="mymap"
        @ready="mapfull()"
      >
        <l-tile-layer :url="url"></l-tile-layer>
        <l-marker :lat-lng="[contentor.lat, contentor.lng]"></l-marker>
        <l-marker :lat-lng="[gps.lat, gps.lng]">
          <l-icon
            :icon-size="dynamicSize"
            :icon-anchor="dynamicAnchor"
            :icon-url="API_URL+'/img/caminhao/normal.png'"
          />
          <l-popup>
            <div>Resinorte</div>
          </l-popup>
        </l-marker>
      </l-map>
    </div>
    <v-container>
      {{ contentor.numero_contentor + " - " + contentor.rua }}
      <v-alert v-if="contentor.obs" dense type="info">
        <br />
        {{ contentor.obs }}
      </v-alert>
      <template v-if="!contentor.sensor && rotas">
        <br />
        Qual o enchimento do contentor?
        <div class="text-center">
          <v-btn :class="isNivelActive(25) ? 'btnNivelActive' : ''"
            @click="contentor.nivel = 25"
            class="btnNiveis ma-2"
            large
            :color="isNivelActive(25) ? '#4caf50' : ''">
            25%
          </v-btn>
          <v-btn :class="isNivelActive(50) ? 'btnNivelActive' : ''"
            @click="contentor.nivel = 50"
            class="btnNiveis ma-2"
            large
            :color="isNivelActive(50) ? '#f0df16' : ''">
            50%
          </v-btn>
          <br />
          <v-btn :class="isNivelActive(75) ? 'btnNivelActive' : ''"
            @click="contentor.nivel = 75"
            class="btnNiveis ma-2"
            large
            :color="isNivelActive(75) ? '#fb8c00' : ''">
            75%
          </v-btn>
          <v-btn :class="isNivelActive(100) ? 'btnNivelActive' : ''"
            @click="contentor.nivel = 100"
            class="btnNiveis ma-2"
            large
            :color="isNivelActive(100) ? '#f82f2f' : ''">
            100%
          </v-btn>
        </div>
      </template>
      <template v-if="contentor.nivel || !rotas">

        <div v-if="rotas">
          <br />
          Recolheu o lixo?
          <div class="text-center">
            <v-btn
              @click="report.recolheuLixo = true"
              class="ma-2"
              large
              :color="report.recolheuLixo ? 'success' : ''"
              >Sim
            </v-btn>
            <v-btn
              @click="report.recolheuLixo = false"
              class="ma-2"
              large
              :color="
                !report.recolheuLixo
                  ? report.recolheuLixo == null
                    ? ''
                    : 'success'
                  : ''
              ">Não
            </v-btn>
            <br />
            <v-text-field
              v-if="report.recolheuLixo==false"
              label="Observação"
              v-model="report.obsLixo"
              name="obsLixo"
              type="text"
            ></v-text-field>
          </div>
        </div>

        <div v-if="!rotas && this.tipoCircuito=='monsters'">
          <br />
          Recolheu os monstros?
          <div class="text-center">
            <v-btn
              @click="report.recolheuMostros=true"
              class="ma-2"
              large
              :color="report.recolheuMostros ?'success':''"
              >Sim
            </v-btn>
            <v-btn
              @click="report.recolheuMostros=false"
              class="ma-2"
              large
              :color="!report.recolheuMostros ?(report.recolheuMostros == null?'':'success'):''"
              >Não
            </v-btn>
            <br />
            <v-text-field
              v-if="report.recolheuMostros==false"
              label="Observação"
              v-model="report.obsLixo"
              name="obsLixo"
              type="text"
            ></v-text-field>
          </div>
        </div>

        <div v-if="!rotas && this.tipoCircuito=='clean'">
          <br />
          Realizou higienização?
          <div class="text-center">
            <v-btn
              @click="report.fezHigenizacao=true"
              class="ma-2"
              large
              :color="report.fezHigenizacao ?'success':''"
              >Sim
            </v-btn>
            <v-btn
              @click="report.fezHigenizacao=false"
              class="ma-2"
              large
              :color="!report.fezHigenizacao ?(report.fezHigenizacao == null?'':'success'):''"
              >Não
            </v-btn>
            <br />
            <v-text-field
              v-if="report.fezHigenizacao==false"
              label="Observação"
              v-model="report.obsLixo"
              name="obsLixo"
              type="text"
            ></v-text-field>
          </div>
        </div>

        <div v-if="!rotas && this.tipoCircuito=='maintenance'">
          <br />
          Realizou manutenção?
          <div class="text-center">
            <v-btn
              @click="report.fezManutencao = true"
              class="ma-2"
              large
              :color="report.fezManutencao ? 'success' : ''"
              >Sim
            </v-btn>
            <v-btn
              @click="report.fezManutencao = false"
              class="ma-2"
              large
              :color="
                !report.fezManutencao ? (report.fezManutencao == null ? '' : 'success') : ''"
              >Não
            </v-btn>
            <br />
            <v-text-field
              v-if="report.fezManutencao==false"
              label="Observação"
              v-model="report.obsLixo"
              name="obsLixo"
              type="text"
            ></v-text-field>
          </div>
        </div>

        <div v-if="rotas">
          <br />
          Lixo fora do contentor?
          <div class="text-center">
            <v-btn
              @click="report.lixofora = true"
              class="ma-2"
              large
              :color="report.lixofora ? 'success' : ''"
              >Sim
            </v-btn>
            <v-btn
              @click="report.lixofora = false"
              class="ma-2"
              large
              :color="
                !report.lixofora ? (report.lixofora == null ? '' : 'success') : ''"
              >Não
            </v-btn>
          </div>
        </div>

        <div v-if="rotas">
          <br />
          Tem anomalia?
          <div class="text-center">
            <v-btn
              @click="report.temAvaria = true"
              class="ma-2"
              large
              :color="report.temAvaria ? 'success' : ''"
              >Sim
            </v-btn>
            <v-btn
              @click="report.temAvaria = false"
              class="ma-2"
              large
              :color="
                !report.temAvaria ?
                  report.temAvaria == null ?
                    '' : 'success'
                : ''"
              >Não
            </v-btn>
            <br />
            <v-btn
              v-if="report.temAvaria"
              @click="report.obsAvaria='Tampa'"
              class="ma-2"
              :color="
                report.obsAvaria != 'Tampa'  ?
                  '' : 'success'"
              >Tampa
            </v-btn>
            <v-btn
              v-if="report.temAvaria"
              @click="report.obsAvaria = 'Saco'"
              class="ma-2"
              :color="
                report.obsAvaria != 'Saco' ?
                  '' : 'success'"
              >Saco
            </v-btn>
            <v-text-field
              v-if="report.temAvaria"
              label="Outro"
              v-model="report.obsAvaria"
              name="obsAvaria"
              type="text"
            ></v-text-field>
          </div>
        </div>

        <div v-if="rotas">
          <br />
          Precisa de Higenização?
          <div class="text-center">
            <v-btn
              @click="report.precisaLimpeza = true"
              class="ma-2"
              large
              :color="report.precisaLimpeza ? 'success' : ''"
              >Sim</v-btn
            >
            <v-btn
              @click="report.precisaLimpeza = false"
              class="ma-2"
              large
              :color="
                !report.precisaLimpeza
                  ? report.precisaLimpeza == null
                    ? ''
                    : 'success'
                : ''"
              >Não
            </v-btn>
          </div>
        </div>

        <div v-if="rotas">
          <br />
          Tem lixo monstro?
          <div class="text-center">
            <v-btn
              @click="report.monstros = true"
              class="ma-2"
              large
              :color="report.monstros ? 'success' : ''"
              >Sim
            </v-btn>
            <v-btn
              @click="report.monstros = false"
              class="ma-2"
              large
              :color="
                !report.monstros ? (report.monstros == null ? '' : 'success') : ''"
              >Não
            </v-btn>
          </div>
        </div>

      </template>
      <uploader
        v-model="report.fileList"
        title="Fotos"
        :autoUpload="false"
        @on-change="onChangeFiles"
        :limit="1"
      ></uploader>
      <div class="text-center">
        <v-progress-circular
          indeterminate
          color="primary"
          v-if="progress"
        ></v-progress-circular>
        <v-btn
          :disabled="
            (this.rotas && (
              progress ||
              (!contentor.nivel || report.recolheuLixo == null) ||
              (contentor.nivel && report.recolheuLixo == null))
            ) ? true : false
          "
          color="blue-grey"
          class="ma-2 white--text"
          @click="setReportContentor()"
        >
          Salvar
          <v-icon right dark>mdi-cloud-upload</v-icon>
        </v-btn>
        <v-btn
          :disabled="progress"
          color="error"
          class="ma-2 white--text"
          @click="goContentores()"
        >
          Cancelar
          <v-icon right dark>mdi-close-circle</v-icon>
        </v-btn>
      </div>
    </v-container>
  </v-card>
</template>
<style scoped>
.d-flex {
  height: 50px;
}
</style>
<script>
/* eslint-disable*/
import { LMap, LTileLayer, LMarker, LIcon, LPopup } from "vue2-leaflet";
import Uploader from "vux-uploader-component";
import mixin from "../plugins/mixin";
import L from "leaflet";
delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

export default {
  name: "Contentor",
   mixins: [mixin],
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LIcon,
    Uploader,
    LPopup
  },
  data() {
    return {
      API_URL: process.env.VUE_APP_API_URL,
      progress: false,
      rotas: [],
      rota: {},
      contentor: {},
      report: {
        recolheuLixo: null,
        lixofora: null,
        tampaPartida: null,
        temAvaria: null,
        monstros: null,
        precisaLimpeza: null,
        recolheuMostros: null,
        fezHigenizacao: null,
        fezManutencao: null,
        fotos: [],
        fotosOff: [],
        lojas: false,
        areasComuns: false,
        obsAvaria: "",
        obsLixo: "",
      },
      tipoCircuito: null,
      gps: null,
      contentorsFiltered: [],
      // Leaflet
      zoom: 18,
      center: "",
      url: "https://{s}.tile.osm.org/{z}/{x}/{y}.png",
      minZoom: 18,
      maxZoom: 18,
      showMap: false,
      // Leaflet
    };
  },
  watch: {},
  created() {
    setInterval(this.setGPS(), 1000 * 10);
  },

  mounted() {
    var item = this.getObjeto("contentorSelecionado");
    this.tipoCircuito = this.getObjeto("tipoCircuito");

    this.rotas = this.getObjeto("rotas");
    if (this.rotas){
      this.rota = this.rotas.find(
        (objeto) => objeto.id === this.getObjeto("rotaSelecionada")
      );
      this.contentor = this.rota.Contentors.find(
        (objeto) => objeto.contentor.id === item.contentor.id
      ).contentor;

    }else{
      this.contentorsFiltered = this.getObjeto("contentorsFiltered");
      this.contentor = item.contentor;
    }

    if (this.contentor.report)
      this.report = this.contentor.report;

    this.showMap = true;
    setTimeout(this.mapfull, 3000);
  },
  computed: {
    dynamicSize() {
      return [this.iconSize, this.iconSize * 1.15];
    },
    dynamicAnchor() {
      return [this.iconSize / 2, this.iconSize * 1.15];
    },
  },
  methods: {
    mapfull() {
      const map = this.$refs.mymap.mapObject;
      map.addControl(new window.L.Control.Fullscreen());
    },
    setGPS() {
      this.gps = this.getObjeto("GPS");
    },
    corBarra(n) {
      var retorno = "deep-orange";
      if (n <= 25) retorno = "light-green darken-4";
      else if (n <= 50) retorno = "light-blue";
      return retorno;
    },
    postReport() {
      this.setProgress(true);
      this.report.data = new Date();
      this.report.historicocircuito = this.getObjeto("historicocircuitos");
      var objeto = this.report;
      objeto.fotosOff = [];
      this.$http
        .post("reports", objeto, {
          headers: {
            Authorization: `Bearer ${this.getObjeto("token")}`,
          },
        })
        .then((response) => {
          this.setProgress(false);
          this.report.id = response.data.id;
          this.guardar();
          this.goContentores();
        })
        .catch(() => {
          this.setProgress(false);
          this.goContentores();
        });
    },

    goContentores() {
      this.$router.push(
        "/contentores#c" + this.getObjeto("contentorSelecionado").contentor.id
      );
    },
    putReport() {
      this.setProgress(true);
      this.report.data = new Date();
      var objeto = this.report;
      objeto.fotosOff = [];
      this.$http
        .put("reports/" + this.report.id, objeto, {
          headers: {
            Authorization: `Bearer ${this.getObjeto("token")}`,
          },
        })
        .then(() => {
          this.setProgress(false);
          this.goContentores();
        })
        .catch(() => {
          this.setOffReport(this.report);
          this.setProgress(false);
        });
    },
    setProgress(progress) {
      this.progress = progress;
    },
    setOffReport(report) {
      this.report.historicocircuito = this.getObjeto("historicocircuitos");
      this.report.data = new Date();
      var reports = this.getObjeto("reportsOff")
        ? this.getObjeto("reportsOff")
        : [];
      var existe = false;
      reports.forEach((obj) => {
        if (obj.contentor === report.contentor) {
          obj = report;
          existe = true;
        }
      });
      if (!existe) reports.push(report);
      this.setObjeto("reportsOff", reports);

      this.goContentores();
    },
    setReportContentor() {
      this.setProgress(true);
      this.guardar();

      if (navigator.onLine) {
        this.uploadFotos().then(() => {
          if (this.report.id) this.putReport();
          else this.postReport();
        });
      } else {
        this.setProgress(false);
        this.setOffReport(this.report);
      }
      this.guardar();
    },
    uploadFotos() {
      var fn = this;
      return new Promise((resolve) => {
        var count = 1;
        const tamanho = this.report.fotosOff.length;
        if (this.report.fotosOff.length > 0) {
          for (let i = 0; i < this.report.fotosOff.length; i++) {
            let formData = new FormData();
            formData.append(
              "files",
              fn.b64toBlob(fn.report.fotosOff[i]),
              Date.now() + ".jpg"
            );
            fn.$http
              .post(this.API_URL+"/upload/", formData, {
                headers: {
                  Authorization: `Bearer ${fn.getObjeto("token")}`,
                },
              })
              .then(function (response) {
                fn.report.fotos.push(response.data[0]);
                fn.report.fotosOff.splice(i, 1);
                if (count == tamanho) resolve();
                else count++;
              })
              .catch(() => {
                if (count == tamanho) resolve();
                else count++;
              });
          }
        } else resolve();
      });
    },
    guardar() {
      this.report.user = this.getObjeto("user").id;
      this.report.rota = this.getObjeto("rotaSelecionada");
      this.report.caminhao = this.getObjeto("caminhao");
      this.report.tipo_circuito = this.getObjeto("circuito");

      var gps = this.getObjeto("GPS");
      if (gps) {
        this.report.latitude = gps.lat + "";
        this.report.longitude = gps.lng + "";
      }
      if (this.contentor.id) this.report.contentor = this.contentor.id;
      this.report.nivel = this.contentor.nivel;
      this.contentor.report = this.report;
      // this.contentor.report.latitude = this.getObjeto("GPS").lat;
      // this.contentor.report.longitude = this.getObjeto("GPS").lng;
      if (this.rotas) {
        for (let i = 0; i < this.rotas.length; i++)
          for (let j = 0; j < this.rotas[i].Contentors.length; j++)
            if (this.rotas[i].Contentors[j].contentor.id == this.contentor.id)
              //marcar o report num contentor que possa estar presente em várias rotas???
              this.rotas[i].Contentors[j].contentor = this.contentor;
        this.setObjeto("rotas", this.rotas);
      }else{
        this.contentorsFiltered.find(cont => cont.id == this.contentor.id).report = this.report;
        this.setObjeto("contentorsFiltered", this.contentorsFiltered);
      }
    },
    onChangeFiles(fileItem) {
      var fn = this;
      var reader = new FileReader();
      reader.readAsDataURL(fileItem.blob);
      reader.onloadend = function () {
        fn.report.fotosOff.push(reader.result);
      };
    },
    b64toBlob(dataURI) {
      var byteString = atob(dataURI.split(",")[1]);
      var ab = new ArrayBuffer(byteString.length);
      var ia = new Uint8Array(ab);
      for (var i = 0; i < byteString.length; i++)
        ia[i] = byteString.charCodeAt(i);
      return new Blob([ab], { type: "image/jpeg" });
    },
    isNivelActive(nivel) {
      if(this.contentor.nivel == nivel || this.contentor.nivel == 0)
        return true;
      else
        return false;
    }
  },
};
</script>

<style scoped>
  .btnNiveis{
    width: 30vw !important;
    color: black;
  }
  .btnNivelActive{
    color: white;
  }
</style>